import "./card-template.scss";
import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import CardCost from "@components/card/card-cost";
import Tariffs from "@components/card/tariffs";
import UsageWays from "./usage-ways";
import OutletsOnMap from "@components/outlets-on-map";
import cn from "classnames";
import Link from "gatsby-link";

export default function CardTemplate({ data, ...rest }) {
	const { markdownRemark: { frontmatter } = {} } = data;
	const {
		title,
		description,
		cost,
		thumbnail,
		tariffs,
		usageWays,
		path
	} = frontmatter;

	let defaultBranches;
	let showMapControls;
	if (path === "/cards/abb") {
		defaultBranches = "akbars";
		showMapControls = false;
	}

	return (
		<Layout {...rest}>
			<section className="card">
				<div className="grid-container card__top">
					<div className="row">
						<div className="col-xs-12 col-sm-6 col-md-offset-2 col-md-5">
							<h2>{title}</h2>
							<p className="card__description">{description}</p>
							<CardCost className="card__cost" value={cost} path={path} />
						</div>
						<div className="col-xs-12 col-sm-6 col-md-3">
							<img
								className={cn(
									"card__image",
									path === "/cards/abb" ? "withoutShadow" : ""
								)}
								src={thumbnail}
								alt="Изображение карты"
							/>
						</div>
						<div className="hidden-xs col-sm-2 col-md-2" />
					</div>
				</div>
				{usageWays && (
					<UsageWays className="full-width" list={usageWays} path={path} />
				)}
				{path === "/cards/abb" && (
					<div className="full-width abb-block">
						<div className="grid-container">
							<div className="row">
								<div className="col-xs-12 col-sm-12 col-md-offset-2 col-md-8">
									<strong>ПАО&nbsp;«АК БАРС»&nbsp;банк</strong> совместно с{" "}
									<strong>АО&nbsp;«Транспортная&nbsp;карта»</strong> выпускает
									банковские карты с транспортным приложением г.Казани (2 в 1 –
									банковская и транспортная карта), с возможностью оплаты
									проезда в городском пассажирском транспорте. При этом на такой
									карте может быть размещен (выбран соответствующий тариф) как{" "}
									<Link to="/cards/etc">
										<strong>билет длительного пользования</strong>
									</Link>
									, так и{" "}
									<a
										rel="noopener noreferrer"
										href="https://www.akbars.ru/individuals/cards/udobnyi-proezdnoi/"
									>
										<strong>«Удобный&nbsp;проездной»</strong>
									</a>{" "}
									- оплата проезда с последующим автоматическим списанием
									денежных средств, находящихся на банковской карте ПАО «АК
									БАРС» банка.
								</div>
							</div>
						</div>
					</div>
				)}
				{tariffs && tariffs.length && <Tariffs data={tariffs} />}
				<OutletsOnMap
					pageContext={{ defaultType: "where-to-refill", defaultBranches }}
					showMapControls={showMapControls}
				/>
			</section>
		</Layout>
	);
}

export const pageQuery = graphql`
	query CardByPath($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			frontmatter {
				path
				title
				cost
				description
				thumbnail
				usageWays {
					title
					description
				}
				tariffs {
					type
					name
					onlyCost
					rideCost
					rideCount
					totalCost
					tariffPeriod
					refillPeriod
					moreInfo
				}
			}
		}
	}
`;
