import "./usage-ways.scss";
import React from "react";
import cn from "classnames";

const UsageWays = ({ className, list, path }) => (
	<div className={cn("usage-ways", className)}>
		<div className="grid-container">
			<div className="row">
				<div className="col-xs-12 col-sm-12 col-md-12">
					{path === "/cards/etc" && (
						<h3 className="usage-ways__header">Способы использования</h3>
					)}
				</div>
			</div>
			<div className="row usages">
				{list.map(({ title, description }, index) => (
					<div
						key={index}
						className={cn("col-xs-12 col-sm-6 col-md-4", {
							"col-md-offset-2": index % 2 === 0
						})}
					>
						<div className="usage-ways__section">
							<h4 className="usage-ways__section-header">{title}</h4>
							<p className="usage-ways__section-text">{description}</p>
						</div>
					</div>
				))}
				<div className="hidden-xs col-sm-2 col-md-2" />
			</div>
		</div>
	</div>
);

export default UsageWays;
