import React, { useEffect, useCallback, useState, useMemo } from "react";
import "./show-more.scss";
import Button from "@components/button";

const ShowMore = ({
	children,
	countForStep = 4,
	buttonClassName,
	attrReset
}) => {
	const [arrLength, setArrLength] = useState(countForStep);
	const arr = useMemo(() => children.slice(0, arrLength), [
		arrLength,
		children
	]);

	// total
	const childrenLength = children.length;

	useEffect(() => {
		setArrLength(countForStep);
	}, [attrReset, countForStep]);

	const showMoreItems = useCallback(() => {
		setArrLength(arrLength + countForStep);
	}, [arrLength, countForStep]);


	


	return (
		<div className="show-more">
			{arr}
			<div className="col-xs-12 col-md-12 col-sm-12 show-more__button-wrapper">
				{arrLength < childrenLength && (
					<Button
						onClick={showMoreItems}
						title="Еще"
						className={`show-more__button-wrapper__button ${buttonClassName}`}
					/>
				)}
			</div>
		</div>
	);
};

export default ShowMore;
