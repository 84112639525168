import "./tariff.scss";
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import icBus from "./ic-bus.svg";
import icTrolley from "./ic-trolley.svg";
import icTram from "./ic-tram.svg";
import icMetro from "./ic-metro.svg";

const icons = {
	bus: icBus,
	trolley: icTrolley,
	tram: icTram,
	metro: icMetro
};

const TariffSection = ({ name, value, hasCurrencySign }) => {
	const isNumber = !Number.isNaN(Number(value)); // todo: это полнейшая бредятина
	return (
		<div
			className={cn("tariff__section", {
				"tariff__section--single-line": isNumber
			})}
		>
			<p className="tariff__section-header">{name}</p>
			<p
				className={isNumber ? "tariff__section-value" : "tariff__section-text"}
			>
				{value} {hasCurrencySign && <>&#8381;</>}
			</p>
		</div>
	);
};

const Tariff = ({
	type,
	name,
	onlyCost,
	rideCost,
	rideCount,
	totalCost,
	tariffPeriod,
	refillPeriod,
	className,
	expanded,
	toggleExpanded,
	moreInfo
}) => (
	<div className={cn("tariff", { "tariff--expanded": expanded }, className)}>
		<div className="tariff__inner">
			<div className="tariff__expandable">
				<div className="tariff__top-wrapper">
					<div className="tariff__header-wrapper">
						<h4 className="tariff__header">{name}</h4>
						{type && type.length <= 1 && (
							<img
								className="tariff__icon"
								src={icons[type[0]]}
								alt={type[0]}
							/>
						)}
					</div>
					{onlyCost && (
						<TariffSection name="Cтоимость" value={onlyCost} hasCurrencySign />
					)}
					{rideCost && (
						<TariffSection
							name="Cтоимость поездки"
							value={rideCost}
							hasCurrencySign
						/>
					)}
					{rideCount && (
						<TariffSection name="Количество поездок" value={rideCount} />
					)}
					{totalCost && (
						<TariffSection
							name="Тариф пополнения"
							value={totalCost}
							hasCurrencySign
						/>
					)}
					{tariffPeriod && (
						<TariffSection name="Срок действия" value={tariffPeriod} />
					)}
					{refillPeriod && (
						<TariffSection name="Срок пополнения" value={refillPeriod} />
					)}
					{moreInfo && (
						<TariffSection name="Подробнее" value={moreInfo} />
					)}
				</div>
			</div>
			<button className="tariff__btn-toggle" onClick={toggleExpanded}>
				{expanded ? "Свернуть" : "Подробнее"}
			</button>
		</div>
	</div>
);

Tariff.propTypes = {
	//type: PropTypes.oneOf(["bus", "trolley", "tram", "metro"]),
	name: PropTypes.string.isRequired,
	rideCost: PropTypes.string,
	rideCount: PropTypes.string,
	totalCost: PropTypes.string,
	tariffPeriod: PropTypes.string,
	refillPeriod: PropTypes.string,
	moreInfo: PropTypes.string,
	className: PropTypes.string,
	expanded: PropTypes.bool,
	toggleExpanded: PropTypes.func.isRequired
};

Tariff.defaultProps = {
	expanded: false
};

export default Tariff;
