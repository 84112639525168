import "./tariffs.scss";
import React, { useState } from "react";
import cn from "classnames";
import Tariff from "./tariff";
import ShowMore from "@components/show-more";

const Tariffs = ({ data, isHeaderShown, path }) => {
	const [expandedIndex, setExpandedIndex] = useState(null);




	
	return (
		<section className="grid-container tariffs">
			<div className="row">
				{isHeaderShown && (
					<div className="col-xs-12 col-sm-12 col-md-12">
						<h3 className="tariffs__header">Тарифы</h3>
					</div>
				)}
			</div>
			<div className="row">
				<ShowMore buttonClassName="tariffs__button-show-more" attrReset={path}>
					{(data || []).map((item, index) => (
						<div
							className={cn("col-xs-12 col-sm-6 col-md-4", {
								"col-md-offset-2": index % 2 === 0
							})}
							key={index}
						>
							<Tariff
								{...item}
								expanded={index === expandedIndex}
								toggleExpanded={() =>
									index === expandedIndex
										? setExpandedIndex(null)
										: setExpandedIndex(index)
								}
								className="tariffs__tariff"
							/>
						</div>
					))}
				</ShowMore>
				<div className="col-sm-2 col-md-2" />
			</div>
		</section>
	);
};

Tariffs.defaultProps = {
	isHeaderShown: true
};

export default Tariffs;
