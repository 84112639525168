import "./card-cost.scss";
import React from "react";
import cn from "classnames";

const CardCost = ({ value, className, path }) => {
	let view;

	if (path === "/cards/bank" || path === "/cards/abb") {
		view = (
			<>
				<h4>Стоимость карты:</h4>
				<span className="card-cost__value">по тарифу банка</span>
			</>
		);
	} else {
		view = value ? (
			<>
				<h4>Стоимость карты:</h4>
				<span className="card-cost__value">{value} &#8381;</span>
			</>
		) : (
			<>
				<h4>Первичный выпуск:</h4>
				<span className="card-cost__value">бесплатно</span>
			</>
		);
	}

	return <div className={cn("card-cost", className)}>{view}</div>;
};

export default CardCost;
